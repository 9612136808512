body .rnc__base {
    top: 0; left: 0;
    width: 100%; height: 100%;
}
body .rnc__notification-container--center {
    width: 100%;
    max-width: initial;
    left: 0;
}
body .rnc__notification-container--bottom-center {
    bottom: 4px;
    left: 12px;
    width: calc(100% - 24px);
    max-width: 100%;
    align-items: initial;
}
body .rnc__notification {
    width: 100%!important;
}
body .rnc__notification-item {
    margin-bottom: 4px;
}
body .rnc__notification-close-mark {
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border: 1px solid rgba(255,255,255,0.2);
}
body .rnc__notification-close-mark:after {
    font-size: 24px;
}
