@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Bold.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Regular.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Medium.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Thin.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Thin.woff') format('woff');
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Black.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Light.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Light.woff') format('woff');
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Ultralight.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Ultralight.woff') format('woff');
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Heavy.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Heavy.woff') format('woff');
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Semibold.woff2') format('woff2'),
    url('../fonts/SFProDisplay-Semibold.woff') format('woff');
    font-weight: 600;
    font-display: swap;
}
